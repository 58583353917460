<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de entradas</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" :min-date="startDate" />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="typeEntry" item-text="nombre" item-value="id"
                        prepend-inner-icon="fas fa-outdent" hint="Selecciona el tipo de entrada."
                        v-model="typeEntryId" label="Tipo entrada" required outlined multiple>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="brands" item-text="nombre" item-value="id" v-model="brandId"
                        label="Sucursales" required outlined prepend-inner-icon="fas fa-id-card-alt"
                        :readonly="!isAdmin" hint="Seleccione una sucursal. Este campo es opcional">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="mt-n12">
                        <v-checkbox v-model="showDetailByProduct" label="Reporte detallado por producto" color="success" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="showDetailByProduct" class="mt-n5">
                        <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                            <v-autocomplete :items="products" item-text="nombre" item-value="id" v-model="productId"
                            prepend-inner-icon="fas fa-boxes" hint="Seleccione un producto. Este campo es obligatorio si el reporte es detallado."
                            label="Producto" required clearable outline :error-messages="errors" :success="valid">
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="providers" item-text="nombre" item-value="idProveedor" v-model="providerId"
                        label="Proveedores" required outlined prepend-inner-icon="fas fa-id-card-alt"
                        clearable hint="Seleccione un proveedor. Este campo es opcional">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12 mt-n12">
                        Mostrar inventario
                        <v-switch v-model="showInventory" :label="showInventory ? 'Habilitado' : 'Deshabilitado'"
                        :color="showInventory ? 'success' : 'secondary'"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" dark @click.native="downloadExcelEntryDetail">
                    <v-icon left>fas fa-file-excel</v-icon>Descargar excel
                </v-btn>
                <v-btn color="blue darken-4" dark class="btn text-white" @click="previewPDF">
                    <v-icon left>fas fa-file-pdf</v-icon>Ver PDF
                </v-btn>
            </v-card-actions>
        </v-card>
        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
            :title-pdf="showInventory ? 'REPORTE DE INVENTARIO' : 'REPORTE DE ENTRADAS'" :handlePdfModal="handlePdfModal"
            :dialog="showPdf" v-if="showPdf">
        </PreviewPDF>
    </v-dialog>
</template>
<script>
import PreviewPDF from '@/components/shared/PreviewPDF.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import sucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import EntradaDetalleDataService from '@/service/entrada/EntradaDetalleDataService';
import ProductoDataService from '@/service/producto/ProductoDataService';
import { getDateLocaleNatural } from '@/utils/DateUtil';


export default {
    name: 'ExcelExportEntryDetail',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        typeEntry: { type: Array },
        providers: { type: Array },
        brands: { type: Array },
        isAdmin: { type: Boolean, default: false }
    },
    components: { SingleCalendar, PreviewPDF },
    created(){
        this.getProducts();
    },
    data(){
        return {
            startDate: '',
            endDate: '',
            typeEntryId: [],
            providerId: null,
            brandId: sucursalDataService.getCurrentSucursalId(),
            productId: null,
            showDetailByProduct: false,
            showInventory: false,
            products: [],
            // to PDF
            pdfBlob: '',
            pdfSource: null,
            showPdf: false,
        }
    },
    methods: {
        async getProducts() {
            this.products = await ProductoDataService.findAll().then( resp => resp.data );
        },
        builtReportName(){
            const startDate = getDateLocaleNatural(this.startDate) ?? '';
            const endDate = getDateLocaleNatural(this.endDate) ?? '';
            const brandName = this.brands.find(brand => brand.id === this.brandId)?.nombre ?? '';
            const providerName = this.providers.find(provider => provider.id === this.providerId)?.nombre ?? '';
            const productName = this.products.find(product => product.id === this.productId)?.nombre ?? '';
            const typeEntryName = this.extractTypeEntryName;
            return `Entradas_${startDate}_${endDate}_${brandName}_${providerName}_${productName}_${typeEntryName}`;
        },
        downloadExcelEntryDetail(){
            const startDate = getDateLocaleNatural(this.startDate) ?? '';
            const endDate = getDateLocaleNatural(this.endDate) ?? '';
            this.$swal.fire({
                title: 'Quieres continuar con la descarga del reporte de entradas?',
                text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const params = { startDate, endDate, sucursalId: this.brandId, typeEntryId: this.extractTypeEntryId,
                        productId: this.productId, providerId: this.providerId,
                        showInventory: this.showInventory, entryNameSelected: this.extractTypeEntryName,
                    };
                    try {
                        return await EntradaDetalleDataService.downloadEntryDetailByExcel(params);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then( result => {
                if (result.isConfirmed) {
                    if( result.value.status == 204 ) {
                        this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                        const url = window.URL.createObjectURL(new Blob([result.value.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${this.builtReportName()}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.$swal.fire({ icon: 'success', title: 'Reporte de entradas', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
                    }
                }
            }).catch(err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
            })
        },
        previewPDF() {
            const startDate = getDateLocaleNatural(this.startDate) ?? '';
            const endDate = getDateLocaleNatural(this.endDate) ?? '';
            const params = { startDate, endDate, sucursalId: this.brandId, typeEntryId: this.extractTypeEntryId,
                productId: this.productId, providerId: this.providerId,
                showInventory: this.showInventory, entryNameSelected: this.extractTypeEntryName
            };
            EntradaDetalleDataService.printEntryDetail(params).then( response => {
                if(response.status == 204) {
                    this.$swal.fire({ icon: 'info', title: 'Reporte PDF', text: 'No hay registros para visualizar', showConfirmButton: false, timer: 2500 });
                    return
                }
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdf = true
            }).catch( error => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al generar el reporte' + error.response.data, showConfirmButton: false, timer: 3500 });
            });
        },
        handlePdfModal(){
            this.showPdf = !this.showPdf
            this.cleanupPdf()
        },
        cleanupPdf(){
            if(this.pdfSource){
                URL.revokeObjectURL(this.pdfSource)
                this.pdfSource = null
            }
            this.pdfBlob = null
        }
    },
    computed: {
        hasTypeEntrySelected(){
            return this.typeEntryId && this.typeEntryId.length > 0
        },
        extractTypeEntryName(){
            return this.typeEntry.filter( type => this.typeEntryId.includes(type.id) ).map( type => type.nombre ).join(', ')
        },
        extractTypeEntryId(){
            return this.typeEntryId.join(',')
        }
    }
}
</script>
import { http, authHeader } from '@/http-commons';

class EntradaDetalleDataService {
    show( entradaDetailId ){
        return http.get(`api/v1/entradaDetalle/show/${entradaDetailId}`, { headers : authHeader() });
    }
    detailByEntradaId( entradaId ){
        return http.get(`api/v1/entradaDetalle/detailByEntradaId/${entradaId}`, { headers : authHeader() });
    }
    delete ( entradaDetailId ){
        return http.delete(`api/v1/entradaDetalle/delete/${entradaDetailId}`, {headers : authHeader() });
    }

    getEntradaDetalleTable( params ){
        return http.get('api/v1/entradaDetalle/listaEntradaDetalle', { params, headers : authHeader() });
    }
    // get products that has stock
    getEntradaDetalleProductoId( productId ){
        return http.get(`api/v1/entradaDetalle/listaEntradaDetalleProducto/${productId}`, { headers : authHeader() });
    }
    // get products that has stock by sucursal.
    getEntradaDetalleProductoSucursal( productId, sucursalId ){
        return http.get(`api/v1/entradaDetalle/getEntradaDetalleBySucursalProducto/${productId}/${sucursalId}`, { headers : authHeader() });
    }
    getEntradaDetalleBySucursal( sucursalId ){
        return http.get(`api/v1/entradaDetalle/getEntradaDetalleBySucursal/${sucursalId}`, { headers : authHeader() });
    }
    downloadEntryDetailByExcel(params){
        return http.get("/api/v1/entradaDetalle/downloadEntryDetailByExcel", { params, responseType: 'blob', headers: authHeader()});   
    }
    printEntryDetail(params){
        return http.get("/api/v1/entradaDetalle/printEntryDetail", { params, responseType: 'blob', headers: authHeader()});   
    }

}
export default new EntradaDetalleDataService();